import * as si from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import * as pic from "../components/pictures";

export const projectsData = [
  {
    title: "YelpCamp",
    description:
      "YelpCamp is a dynamic campgrounds rating web application centered around CRUD functionalities—enabling users to Create, Read, Update, and Delete campgrounds effortlessly. Users can also contribute feedback and reviews on campgrounds, managing their own contributions with ease.",
    cardImage: { src: pic.yelpCampPic, alt: "yelpCamp" },
    images: [
      { src: pic.yelpCamp1, alt: "yelpCamp" },
      { src: pic.yelpCamp2, alt: "yelpCamp" },
      { src: pic.yelpCamp3, alt: "yelpCamp" },
      { src: pic.yelpCamp4, alt: "yelpCamp" },
      { src: pic.yelpCamp5, alt: "yelpCamp" },
      { src: pic.yelpCamp6, alt: "yelpCamp" },
    ],
    technologies: [
      <si.SiJavascript className="icon" size="2.5rem" color="#2F4F4F" />,
      <DiNodejs className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiExpress className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiMongodb className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiHtml5 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiCss3 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiBootstrap className="icon" size="2.5rem" color="#2F4F4F" />,
    ],
  },
  {
    title: "Expiration Date Tracker",
    description: `Ever found yourself using expired products unknowingly? Fear not! With Expiry Date Tracker, I've crafted a solution to safeguard both your well-being and mine. This innovative website empowers you to input your items along with their expiration dates, receiving timely email reminders a day before expiry to ensure utmost product safety. Seamlessly monitor the status of each item, distinguishing between those safe for use and those past their prime.`,
    cardImage: { src: pic.expirationDatePic, alt: "expirationDate" },
    images: [
      { src: pic.expiryDate1, alt: "expirationDate" },
      { src: pic.expiryDate2, alt: "expirationDate" },
      { src: pic.expiryDate3, alt: "expirationDate" },
      { src: pic.expiryDate4, alt: "expirationDate" },
      { src: pic.expiryDate5, alt: "expirationDate" },
    ],
    technologies: [
      <si.SiJavascript className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiReact className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiRedux className="icon" size="2.5rem" color="#2F4F4F" />,
      <DiNodejs className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiExpress className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiMongodb className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiHtml5 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiCss3 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiBootstrap className="icon" size="2.5rem" color="#2F4F4F" />,
    ],
  },
  {
    title: "Hessah Store",
    description:
      "Hessah Store is an advanced MERN-stack e-commerce web application featuring dual interfaces: one for clients and one for administrators. Clients enjoy a seamless experience, with the ability to search, view products, add to cart, and review their purchase history. Meanwhile, administrators have comprehensive control, including the capability to add, edit, and delete products and categories, as well as access to complete order histories, including client information.",
    cardImage: { src: pic.hessahStorePic, alt: "hessahStore" },
    images: [
      { src: pic.hessahStore1, alt: "hessahStore" },
      { src: pic.hessahStore2, alt: "hessahStore" },
      { src: pic.hessahStore3, alt: "hessahStore" },
      { src: pic.hessahStore4, alt: "hessahStore" },
      { src: pic.hessahStore5, alt: "hessahStore" },
      { src: pic.hessahStore6, alt: "hessahStore" },
      { src: pic.hessahStore7, alt: "hessahStore" },
      { src: pic.hessahStore8, alt: "hessahStore" },
      { src: pic.hessahStore9, alt: "hessahStore" },
      { src: pic.hessahStore10, alt: "hessahStore" },
    ],
    technologies: [
      <si.SiJavascript className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiReact className="icon" size="2.5rem" color="#2F4F4F" />,
      <DiNodejs className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiExpress className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiMongodb className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiHtml5 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiCss3 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiBootstrap className="icon" size="2.5rem" color="#2F4F4F" />,
    ],
  },
  {
    title: "Notewrote Blog",
    description:
      "Notewrote offers a personalized blogging experience, built with Ruby on Rails. Users can explore articles, sign up, and log in effortlessly. Authenticated users gain the ability to create, edit, and delete their articles, making Notewrote the ideal platform to share your voice and stories.",
    cardImage: { src: pic.notewrote, alt: "note" },
    images: [
      { src: pic.noteWrote1, alt: "note" },
      { src: pic.noteWrote2, alt: "note" },
      { src: pic.noteWrote3, alt: "note" },
      { src: pic.noteWrote4, alt: "note" },
      { src: pic.noteWrote5, alt: "note" },
      { src: pic.noteWrote6, alt: "note" },
    ],
    technologies: [
      <si.SiRuby className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiRubyonrails className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiPostgresql className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiHtml5 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiCss3 className="icon" size="2.5rem" color="#2F4F4F" />,
      <si.SiBootstrap className="icon" size="2.5rem" color="#2F4F4F" />,
    ],
  },
  // Add data for other projects here
];
